<script setup>
import {useCurrencyStore} from "~/store/currencyStore.js";
import {computed} from 'vue';

const store = useCurrencyStore();

const currentCurrency = computed(() => store.currency)

import ProductPrice from "~/components/molecules/ProductPrice.vue";
import Text from "~/components/atoms/Text.vue";
import ProductUnit from "~/components/molecules/ProductUnit.vue";

const props = defineProps({
  price: {
    required: true
  },
  currency: {
    type: String,
  },
  unit: {
    type: String,
    default: 'm2'
  },
  minVolumeQty: {
    type: Number,
    required: true
  }
});
</script>

<template>
  <div class="volume-block g-4">
    <Text font-size="13px" font-weight="600">{{ $t('product.volume.buy_from') }} {{ minVolumeQty }}
      <ProductUnit :unit="unit" :sup="true"/>
    </Text>
    <ProductPrice :currency="currentCurrency" gap="5px"
                  class="product"
                  :digits="0"
                  :light-unit="true"
                  unit-margin-top="5px"
                  :price="price" :unit="unit"/>
  </div>
</template>


<style scoped lang="scss">
@import "@/assets/scss/variables.scss";

.volume-block {
  width: 236px;
  max-height: 111px;
  background: $bronze;
  padding: 24px 32px;
}
</style>
