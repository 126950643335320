<script setup lang="ts">

</script>

<template>
  <div class="info-block row full-width">
    <ProductLabel :absolute="false" :name="name" :background="background" :color="color"/>
    <div>{{ text }}</div>
  </div>
</template>

<script>
import ProductLabel from "~/components/molecules/ProductLabel.vue";

export default {
  props: {
    name: {
      type: String,
    },
    text: {
      type: String,
    },
    color: {
      type: String,
    },
    background: {
      type: String,
    },
  },
  components: {ProductLabel},
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";

.info-block {
  background: $grey;
  padding: 22px 20px;
  gap: 20px;
  font-size: 12px;
  color: #000;
}
</style>
