<script setup lang="ts">
useHead({
  script: [
    {
      src: 'https://js.klarna.com/web-sdk/v1/klarna.js',
      async: true,
      'data-client-id': 'b575e1e3-99c6-5900-8098-5cb8b8b0e18f'
    }
  ]
});

const props = defineProps({
  totalPrice: {
    type: Number,
    required: true
  },
});

onMounted(() => {
  setTimeout(() => {
    window.KlarnaOnsiteService.push({eventName: 'refresh-placements'});
  }, 1000);
});

</script>

<template>
  <div class="klarna-parts">
    <!-- Placement v2 -->
    <klarna-placement
        data-key="credit-promotion-badge"
        data-locale="sv-SE"
        :data-purchase-amount="props.totalPrice?.toFixed(2) * 100"
    ></klarna-placement>
  </div>
</template>

<style scoped lang="scss">
</style>
