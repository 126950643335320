<template>
  <nuxt-link target="_blank" :to="slug" class="select-block row no-flex">
    <NuxtImg width="45" height="45"
             :src="image"
             :alt="name"/>
    <div class="value"><small>{{ $t('collection.collection') }}</small> <strong>{{ name }}</strong></div>
    <div class="icon no-flex">
      <img style="width: 16px;height: 16px;" src="/images/moves/external-link.png"/>
    </div>
  </nuxt-link>
</template>

<script>
export default {
  props: ['slug', 'image', 'name']
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";

.select-block {
  width: 100%;
  border: 1px solid $border-grey;
  border-radius: 5px;
  gap: 15px;
  margin-left: auto;
  height: 57px;
  cursor: pointer;
  padding: 5px 14px;
  position: relative;
  display: flex;
  font-size: 13px;

  &:hover {
    background: hsla(0, 0%, 97.6%, .9);
  }
}

img {
  width: 45px;
  height: 45px;
  border-radius: 5px;
}

small {
  color: rgb(112, 112, 112);
}
</style>
