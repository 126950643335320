<script setup>
import Heading from "~/components/atoms/Heading.vue";
import Text from "~/components/atoms/Text.vue";
import List from "~/components/molecules/List.vue";
import Carousel from "~/components/molecules/Carousel.vue";
import {useHead} from "#app";

const props = defineProps({
  instagram: {
    type: Array,
    required: true
  }
})

const currentIndex = ref(0)

const left = () => {
  if (currentIndex.value > 0) {
    currentIndex.value--
  } else {
    currentIndex.value = props.instagram.length - 1
  }
};

const right = () => {
  if (currentIndex.value < props.instagram.length - 1) {
    currentIndex.value++;
  } else {
    currentIndex.value = 0;
  }
};

onMounted(() => {
  useHead({
    script: [
      {
        src: '//www.instagram.com/embed.js',
        async: true,
      }
    ]
  })
})

const getListSlotName = (index) => {
  return `li-content-${index}`;
}
</script>


<template>
  <section>
    <div class="insta-block row full-width flex-start column-mobile">
      <div class="g-64">
        <div>
          <div class="hashtag row" style="gap: 5px;">
            <NuxtImg width="18px" height="18px" src="https://hillceramic.se/img/web-icons/instagram.png"/>
            <Text font-size="18px">#hillceramic</Text>
          </div>
          <div style="gap:22px">
            <Heading font-size="32px" tag="h2">{{ $t('instagram.title') }}</Heading>
            <List font-weight="400" gap="15px" font-size="16px" :items="[
                $t('instagram.text.0'),
                $t('instagram.text.1')+' <b>#hillceramic</b>',
                $t('instagram.text.2'),
            ]">
              <template #default="item">
                <span v-html="item.item"></span>
              </template>
            </List>
          </div>
        </div>

        <div class="row g-32 left-auto arrows" v-if="instagram.length > 1">
          <div class="swiper-btn left" @click="left" style="transform: rotate(180deg)">
            <img class="white" src="/images/moves/arrow.png"/>
          </div>
          <div class="swiper-btn left" @click="right">
            <img class="white" src="/images/moves/arrow.png"/>
          </div>
        </div>
      </div>
      <div style="max-width: 100%; width: 100%">
        <div class="frames flex-center">
          <div class="frame" data-index="0" style="margin-left: 0px;">
            <Carousel :no-arrows="true" :modelValue="currentIndex" :items="instagram"
                      @change="currentIndex = $event.currentSlideIndex;"
                      :items-to-scroll="1"
                      :items-to-show="1">
              <template #default="{item}">
                <div v-html="item"></div>
              </template>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
@import "@/assets/scss/mixins.scss";

.arrows {
  @include smd {
    margin-bottom: 32px;
    margin-top: -16px;
  }
}

.swiper-btn {
  width: 48px;
  height: 48px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 32px 48px rgba(82, 82, 82, 0.08);
  background: rgba(255, 255, 255, 0.9);
  cursor: pointer;
  z-index: 9;

  &:hover {
    background: rgb(249 249 249 / 90%);
    color: white;

    @include smd {
      background: rgba(255, 255, 255, 0.9);
      color: black;
    }
  }

  img {
    height: 30%;
    width: auto;
  }

  &.right {
    left: unset;
    right: -30px;
    float: unset;
  }
}

.insta-block {
  font-size: 22px;
  font-weight: 600;
  height: 687px;
  color: #000;
  padding: 0 120px;

  @include medium {
    padding: 0 30px;
  }

  @include smd {
    padding: 0;
    height: auto;
  }

  &:deep(iframe) {
    width: 466px;
    border: 0 !important;

    @include smd {
      width: 100%;
    }
  }
}

.frame {
  width: 466px !important;

  @include smd {
    width: 100% !important;
  }
}

.insta-block > div:first-child {
  max-width: 373px;
}
</style>
