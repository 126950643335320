<script setup>
import Image from "~/components/atoms/Image.vue";
import Text from "~/components/atoms/Text.vue";
import ProductPrice from "~/components/molecules/ProductPrice.vue";
import Button from "~/components/atoms/Button.vue";
import MarketCartIcon from "~/components/icons/MarketCartIcon.vue";
import SpeedTruckIcon from "~/components/icons/SpeedTruckIcon.vue";
import FilterIcon from "~/components/icons/FilterIcon.vue";
import FormInput from "~/components/atoms/FormInput.vue";
import {useCartStore} from "~/store/cartStore";
import CartIcon from "~/components/icons/CartIcon.vue";
import {useCustomFetch} from '~/composables/customFetch.js';

const props = defineProps({
  image: {
    required: true
  },
  name: {
    required: true
  },
  price: {
    required: true
  },
  deliveryTime: {
    required: true
  },
  samplePrice: {
    required: true
  },
  unit: {
    required: true
  },
  id: {
    required: true
  },
})

const store = useCartStore()
const surface = ref('all')
const products = ref([])

const addAlternative = (id) => {
  store.addItemToCart({id, count: 1, sample: 1, alternative: 1, source: store.sources.alternative_sample_alternative})
}

const getSampleAlternatives = () => {
  useCustomFetch(`product/${props.id}/sample-alternative`, {
    query: {
      cart_token: store.content.token,
      surface: surface.value
    }
  }, true).then((response) => {
    products.value = response.products
  })
}

watch(surface, () => {
  getSampleAlternatives()
})

onMounted(() => {
  getSampleAlternatives()
})
</script>

<template>
  <div class="sample-form g-24">
    <div class="g-24 no-flex">
      <div class="g-16 no-flex">
        <div class="row full-width g-16 stretch">
          <div class="image">
            <Image :src="image"/>
          </div>
          <div class="space-between">
            <Text>{{ name }}</Text>
            <div class="no-flex row full-width">
              <div class="no-flex">
                <ProductPrice :price="price" :unit="unit"/>
                <div class="no-flex" style="text-transform: capitalize;" v-html="deliveryTime"></div>
              </div>

              <Button class="no-flex left-auto" type="black"
                      @click="store.addItemToCart({id:props.id, count:1, sample:1, source:store.sources.alternative_sample_main}); $emit('close')">
                <div class="row g-8">
                  <CartIcon width="16px" stroke="white"/>
                  <ProductPrice :digits="0" style="color:white; font-weight: 400; width: fit-content;"
                                :price="samplePrice"/>
                </div>
              </Button>
            </div>
          </div>
        </div>

        <Text>Denna provplatta är en beställningsvara som har leveranstid 10-14 dagar.</Text>
      </div>

      <div class="no-flex separator full-width">
        <div class="line"></div>
        <div class="row g-8 center-auto title">
          <Text tag="strong" uppercase>ELLER SNABB LEVERANS</Text>
          <SpeedTruckIcon style="height: 22px; width: 22px;" stroke-width="0.5px" stroke="black"/>
        </div>
      </div>

      <Text>Lägg till några av våra bästsäljare i varukorgen, leveranstiden är 2-3 dagar och du får upp till 90% rabatt
        på
        dessa varuprover:
      </Text>

      <div class="no-flex row g-16">
        <FilterIcon stroke="black"/>
        <FormInput v-model="surface" type="select" :options="[
          {key:'all', value:'Alla'},
          {key:'Blank', value:'Blank'},
          {key:'Matt', value:'Matt'},
          {key:'Rustik', value:'Rustik'},
          ]" label="Välj Yta"/>
      </div>
    </div>

    <div class="scroll">
      <div class="products g-16">
        <div v-for="product in products" class="row full-width g-16 stretch">
          <div class="image">
            <Image :src="product.image"/>
          </div>
          <div class="space-between">
            <Text>{{ product.name }}</Text>
            <div class="no-flex row full-width">
              <div class="no-flex">
                <ProductPrice :price="product.price" :unit="product.unit"/>
              </div>

              <Button :timeless-check="true" :check-after-click="true" class="no-flex left-auto" type="black"
                      @click="addAlternative(product.id)">
                <div class="row g-8">
                  <MarketCartIcon stroke="white"/>
                  <ProductPrice :digits="0" style="color:white; font-weight: 400; width: fit-content;"
                                :price="product.sample_price"/>
                </div>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <br>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";

.image {
  max-width: 90px;
  height: 90px;
}

.sample-form {
  width: 100%;
  max-width: 560px;
  max-height: 100%;
}

.line {
  background: $black;
  position: absolute;
  left: 0;
  right: 0;
  height: 2px;
  top: 9px;
}

.title {
  background: white;
  padding: 0 20px;
}

.scroll {
  overflow: auto;
}
</style>
