<template>
  <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="32.000000pt" height="32.000000pt"
       viewBox="0 0 32.000000 32.000000" preserveAspectRatio="xMidYMid meet">

    <g transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path
          d="M5 311 c-4 -7 5 -11 23 -11 28 0 30 -3 41 -62 7 -35 17 -80 22 -100 l10 -38 89 0 c60 0 91 4 97 13 11 16 31 126 25 132 -3 3 -54 5 -113 5 l-108 0 -9 33 c-7 26 -14 33 -39 35 -17 2 -34 -2 -38 -7z m280 -128 c-4 -27 -10 -52 -14 -56 -4 -4 -41 -7 -82 -5 l-74 3 -11 53 -12 52 100 0 100 0 -7 -47z"/>
      <path d="M100 40 c0 -13 7 -20 20 -20 13 0 20 7 20 20 0 13 -7 20 -20 20 -13 0 -20 -7 -20 -20z"/>
      <path d="M245 51 c-9 -16 3 -31 26 -31 14 0 19 6 17 17 -4 20 -33 29 -43 14z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'AlternativeCartIcon'
}
</script>
