<script setup>

import ImageGallery from "~/components/molecules/ImageGallery.vue";
import GreyBlock from "~/components/templates/GreyBlock.vue";
import Reviews from "~/components/organisms/Reviews.vue";
import ProductMainDescription from "~/components/organisms/ProductMainDescription.vue";
import CollectionsPreview from "~/components/organisms/CollectionsPreview.vue";
import BasicBlock from "~/components/molecules/BasicBlock.vue";
import SalesArguments from "~/components/plugins/SalesArguments/SalesArguments.vue";
import ProductsCarousel from "~/components/organisms/ProductsCarousel.vue";
import ShopTheLook from "~/components/organisms/ShopTheLook.vue";
import Instagram from "~/components/organisms/Instagram.vue";
import ProductMainInfo from "~/components/organisms/ProductMainInfo.vue";
import ProductSpoiler from "~/components/organisms/ProductSpoiler.vue";
import Image from "~/components/atoms/Image.vue";
import BetterWith from "~/components/organisms/BetterWith.vue";
import Text from "~/components/atoms/Text.vue";
import SampleForm from "~/components/organisms/SampleForm.vue";
import Modal from "~/components/molecules/Modal.vue";
import Breadcrumbs from "~/components/organisms/Breadcrumbs.vue";
import {useLastVisitedProducts} from "~/store/lastVisitedProducts.js";
import {fetchWithErrorHandling} from '~/composables/fetchWithErrorHandling';
import {useUserStore} from "~/store/userStore.js";
import AdminButton from "~/components/molecules/AdminButton.vue";
import {defineAsyncComponent} from "vue";
import Button from "~/components/atoms/Button.vue";
import Supplementary from "~/components/organisms/Supplementary.vue";
import ExpandIcon from "~/components/icons/ExpandIcon.vue";

const ProductSelectSide = defineAsyncComponent({
  loader: () => import(`~/components/organisms/ProductSelectSide.vue`)
})

const route = useRoute()
const slug = route.params.slug.join('/');
const lastVisitedProductsStore = useLastVisitedProducts()

const userStore = useUserStore()

let data = await fetchWithErrorHandling(`product/${slug}`)

const {product, reviews} = data?.value ?? {}

if (product) {
  useHead({
    title: `${product.name} ${product.name.length < 54 ? '| Hill Ceramic®' : ''}`,
    meta: [
      {
        hid: 'description',
        name: 'description',
        content: `Köp ${product.name} (${product.sku}) hos Hillceramic.se. ✓ Snabb leverans! ✓ Mer än 10 000 kakel och klinker ✓ Kampanjpriser`
      },
      {
        hid: 'og:title',
        property: 'og:title',
        content: product.name + (product.name.length < 54 ? ' | Hill Ceramic®' : '')
      },
      {hid: 'og:image', property: 'og:image', content: product.files?.frontal_images[0]?.url}
    ],
    script: [
      {
        src: 'https://eu-library.klarnaservices.com/lib.js',
        async: true,
        'data-client-id': 'b575e1e3-99c6-5900-8098-5cb8b8b0e18f'
      },
      {
        type: 'application/ld+json',
        children: product.breadcrumbs.structured_data,
      },
      {
        type: 'application/ld+json',
        children: product.structured_data?.data,
      },
      {
        type: 'application/ld+json',
        children: product.images_structured_data?.data,
      },
    ]
  })

  lastVisitedProductsStore.addProduct(product.id)
}

const showSampleForm = ref(false)

const supplementary = computed(() => {
  if (!product) {
    return []
  }

  return (product.supplementary.mosaic ? [product.supplementary.mosaic] : []).concat(product.supplementary.supplementary_bundles.concat(product.supplementary.combo))
})

const hoveredImage = ref(null)

const image1 = computed(() => {
  if (!product) {
    return null
  }

  const image = product.files.interior_images[0]?.url

  if (image) {
    return image
  }

  return product.files.frontal_images[0]?.url
})

const image2 = computed(() => {
  if (!product) {
    return null
  }

  let image = '';
  if (product.files.interior_images[1]) {

    image = product.files.interior_images[1]['url']
  }

  if (image) {
    return image
  }

  image = product.files.frontal_images[1]?.url

  if (!image) {
    return image1.value
  }

  return image
})

const activeSelect = ref(null)

const currentBundle = ref(null)
const bundleToken = ref(null)

onMounted(() => {
  if (product) {
    if (typeof (dataLayer) != "undefined" && dataLayer !== null) {
      dataLayer?.push({ecommerce: null});
      dataLayer?.push({
        'event': 'view_item',
        'ecommerce': {
          'items': [{
            ...product.data_layer?.ga4.item[0]
          }]
        }
      });

      dataLayer?.push({ecommerce: null});
      dataLayer?.push({
        'event': 'productDetails',
        'ecommerce': {
          'detail': {
            'products': [{
              ...product.data_layer?.ga3.products[0]
            }]
          }
        }
      });
    }

    if (process.client) {
      var _learnq = window._learnq;

      _learnq?.push(["track", "Viewed Product", product.data_layer?.klaviyo]);
    }
  }
})
</script>

<template>
  <template v-if="product">
    <div class="container product-page">
      <div class="main-info full-width flex-start">
        <div class="left-column mobile-padding breadcrumbs" v-if="!$device.isDesktop">
          <Breadcrumbs :breadcrumbs="product.breadcrumbs.data"/>
        </div>
        <div class="left-column gallery">
          <ImageGallery :hoveredImage="hoveredImage" :timer_date="product.timer ? product.timer.date : null"
                        :full_images="product.files.full_images"
                        :previews="product.files.previews"/>
        </div>
        <div class="right-column mobile-padding">
          <ProductMainInfo
              :bundleToken="bundleToken"
              @bundleChanged="currentBundle=$event"
              @selectChange="activeSelect=$event"
              @alternativeHover="hoveredImage = $event"
              @alternativeUnhover="hoveredImage = null"
              :supplementary="product.supplementary.supplementary_bundles.filter(bundle => product.supplementary.supplementary_bundles_mandatory.map(item=>item.id).includes(bundle.id))"
              @show-sample-form="showSampleForm=true" :product="product"/>
        </div>

        <div class="left-column">
          <div class="g-24">
            <div class="description-info main-info-description mobile-padding" v-if="product.main_info_description">
              <Text tag="strong">{{ $t('product.important_info') }}:</Text>
              <div v-html="product.main_info_description"></div>
            </div>
            <div class="description-info mobile-padding" v-if="product.info_description">
              <Text tag="strong">Info:</Text>
              <div v-html="product.info_description"></div>
            </div>

            <section v-if="product.bundles">
              <BetterWith :label="$t('product.package_includes')" :title="$t('base.contents')" :sku="product.sku"
                          :items="product.bundles.products"/>
            </section>

            <div>
              <section v-if="supplementary.length">
                <BetterWith
                    :label="$t('product.best_combination')"
                    :title="$t('product.better_with')" :sku="product.sku"
                    :items="supplementary"/>
              </section>

              <section>
                <div class="description">
                  <div v-if="$device.isDesktop" class="image full-width over-hidden" style="aspect-ratio: 1 / .64;">
                    <Image :src="image1"
                           :alt="product.name"/>
                  </div>

                  <ProductSpoiler :catalogs="product.catalogs"
                                  :pdfs="product.pdfs"
                                  :collection_slug="product.collection.slug"
                                  :main_category="product.mainCategory"
                                  :is_tile="product.is_tile"
                                  :niche_id="product.niche_id"
                                  :pei_level="product.pei_level"
                                  :attributes="product.attribute_groups" :sku="product.sku"
                                  :attribute_icons="product.attribute_icons"/>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ProductSelectSide v-if="product.selects && activeSelect!==null" :select_image="product.selects.select_image"
                       @close="activeSelect = null"
                       :sku="product.sku" :activeSelect="product.selects.selects[activeSelect]"
                       :collection="product.collection"/>

    <div class="row full-width flex-start admin-panel" v-if="userStore.isAdmin && userStore.showAdmin && product.admin">
      <div class="g-16">
        <div>
          <Text>In Stock</Text>
          <Text tag="strong">{{ product.admin.in_stock }} <span v-html="product.admin.unit_block"></span></Text>
        </div>
        <div>
          <Text>Supplier Stock</Text>
          <Text tag="strong">{{ product.admin.supplier_stock }} <span v-html="product.admin.unit_block"></span></Text>
        </div>
        <div>
          <Text>Samples in stock</Text>
          <Text tag="strong">{{ product.admin.samples_in_stock }} <span>st</span></Text>
        </div>
        <div>
          <Text>Sold All Time</Text>
          <Text tag="strong">{{ product.admin.sold_all_time }} <span v-html="product.admin.unit_block"></span></Text>
        </div>
      </div>

      <div class="g-16">
        <div>
          <Text>Available</Text>
          <Text tag="strong">{{ product.admin.available }} <span v-html="product.admin.unit_block"></span></Text>
        </div>
        <div>
          <Text>Incoming</Text>
          <Text tag="strong">{{ product.admin.incoming }} <span v-html="product.admin.unit_block"></span></Text>
        </div>
        <div>
          <Text>Samples Available</Text>
          <Text tag="strong">{{ product.admin?.samples_available }} <span>st</span></Text>
        </div>
        <div>
          <Text>Return Rate</Text>
          <Text tag="strong">
            <template v-if="product.admin?.return_product_qty_total > 0">
              {{ product.admin?.percents_return }}% ({{
                product.admin?.return_product_qty_total
              }}/{{ product.admin?.product_orders_qty }})
            </template>
            <template v-else>None</template>
          </Text>
        </div>
      </div>
    </div>

    <Modal minHeight="95%" v-if="currentBundle" @close="currentBundle=null">
      <template #title>{{ currentBundle.name }}</template>
      <template #default>
        <Supplementary @close="bundleToken=Math.random()" :key="currentBundle.id" :bundle="currentBundle">
          <Button @click="bundleToken=Math.random()" type="bordered" class="tall full-width">Nej, tack!</Button>
        </Supplementary>
      </template>
    </Modal>

    <Modal @close="showSampleForm=false" v-if="showSampleForm" minHeight="95%" style="min-height: 95%;">
      <template #title>Du lägger till följande i kundvagnen:</template>
      <template #default>
        <SampleForm @close="showSampleForm=false" :price="product.prices.standard" :name="product.name"
                    :image="product.files.frontal_images[0]?.url"
                    :unit="product.unit"
                    :sku="product.sku" :id="product.id" :delivery-time="product.sample_delivery_time"
                    :sample-price="product.sample_price"
        />
      </template>
    </Modal>

    <div style="margin-top: 50px;">
      <div class="g-96">
        <GreyBlock style="padding-top: 60px;">
          <div class="container full-width g-96" style="min-height: 600px;">
            <section class="mobile-padding" v-if="product.instagram?.length">
              <Instagram :instagram="product.instagram"/>
            </section>
            <section v-if="product.shop_the_looks" v-for="shop_the_look in product.shop_the_looks">
              <ShopTheLook  :products="shop_the_look.products" :preview="shop_the_look.file_cut"
                            :image="shop_the_look.file"/>
            </section>
            <section class="mobile-padding">
              <Reviews :data="reviews"/>
            </section>
            <section>
              <ProductMainDescription :image1="image1"
                                      :image2="image2"
                                      v-if="product.description" :text="product.description" :sku="product.sku"/>
            </section>
            <section class="mobile-padding">
              <BasicBlock>
                <template v-slot:title>{{ $t("product.related_collections") }}</template>
                <nuxt-link v-if="!$device.isMobile" class="all-collections" to="/kollektioner/">Se alla kollektioner
                  <ExpandIcon/>
                </nuxt-link>
                <CollectionsPreview :collections="product.related_collections"/>
              </BasicBlock>
              <nuxt-link v-if="$device.isMobile" class="all-collections" to="/kollektioner/">Se alla kollektioner
                <ExpandIcon/>
              </nuxt-link>
            </section>
            <section class="mobile-padding" v-if="product.recommended_products.length">
              <BasicBlock>
                <template v-slot:title>{{ $t("product.recommended_products") }}</template>
                <ProductsCarousel :products="product.recommended_products"/>
              </BasicBlock>
            </section>
          </div>
        </GreyBlock>


        <SalesArguments/>
      </div>
    </div>

    <AdminButton v-if="userStore.isAdmin">
      <slot name="admin"/>
    </AdminButton>
  </template>
</template>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/mixins.scss";

.admin-panel {
  position: fixed;
  z-index: 999;
  bottom: 18%;
  width: 296px;
  right: 20px;
  background: #F9F9F9;
  padding: 20px;
  border-radius: 6px;
}

.container {
  @include mobile {
    padding: 0;
  }
}

.all-collections {
  position: absolute;
  right: 10px;
  top: 10px;
  font-weight: 600;
  font-size: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;

  @include mobile {
    position: relative;
    margin-left: auto;
    width: 100%;
    text-align: right;
    justify-content: end;
    margin-top: 20px;
    font-weight: 500;
  }
}

.main-info {
  display: block;
}

.gallery {
  margin-bottom: 24px;

  @include mobile {
    margin-bottom: 16px;
  }

  &:deep(.numbers) {
    @include smd {
      font-size: 30px;
    }
  }

  &:deep(.meaning) {
    @include smd {
      font-size: 10px !important;
      margin-top: 5px;
      font-weight: 500 !important;
    }
  }

  &:deep(.dots) {
    @include smd {
      margin: 0px 5px 0;
    }
  }
}

.main-info-description {
  padding: 16px 12px;
  background: $violet;
}

.description-info {
  font-size: 13px;
}

.description-info:deep(ul) {
  padding: 0;
  list-style: none;
}

.description-info:deep(div) {
  display: block;
}

.description-info:deep(iframe) {
  width: 100%;
}

.right-column {
  display: block;
  margin-right: 37px;
  width: 32%;
  float: right;
  position: sticky;
  position: -webkit-sticky;
  top: 10px;

  @include small-desktop {
    width: 31%;
  }

  @include medium {
    width: 40%;
    margin-right: 0;
  }

  @include smd {
    position: relative;
    width: 100%;
    top: 0;
    margin-bottom: 24px;
    margin-right: 0;
  }
}

.left-column {
  padding-right: 65px;
  justify-content: start;
  gap: 20px;
  width: 65%;
  display: inline-block;
  //z-index: -1;

  &.breadcrumbs {
    @include smd {
      margin-bottom: 8px;
    }
  }

  @include medium {
    padding-right: 0;
    width: 56%;
  }

  @include smd {
    padding-right: unset;
    max-width: unset;
    width: 100%;
  }
}

.admin-panel {
  strong {
    font-size: 18px;
  }
}
</style>
