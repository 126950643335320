<script setup>
import Heading from "~/components/atoms/Heading.vue";
import Carousel from "~/components/molecules/Carousel.vue";
import BetterWithProduct from "~/components/molecules/ProductPage/BetterWithProduct.vue";
import {defineAsyncComponent} from "vue";
import Text from "~/components/atoms/Text.vue";

const props = defineProps({
  sku: {
    type: String,
    required: true
  },
  title: {
    type: String,
  },
  items: {
    type: Array,
    required: true
  },
  label: {
    type: String,
  }
})

const SupplementaryModal = defineAsyncComponent({
  loader: () => import(`~/components/templates/SupplementarySide.vue`)
})

const chosenBundle = ref(null)

const handleBundleClick = (bundle) => {
  chosenBundle.value = bundle
}
</script>

<template>
  <div class="supplementary g-24">
    <div class="row g-16 column-mobile flex-center mobile-padding title">
      <Heading tag="h2">{{ props.title }}</Heading>
      <div class="green-label flex-center" v-if="label">
        <Text tag="strong">{{ label }}</Text>
      </div>
    </div>

    <div class="slides">
      <div class="carousel-container">
        <Carousel
            :items-to-show-mobile="2.3"
            :items-to-show-tablet="3.3"
            :items="items"
            :items-to-show="4.3" :itemsToScroll="2" :infinite="true" :dots="false">
          <template #default="{item}">
            <BetterWithProduct @click="handleBundleClick" :product="item"/>
          </template>
        </Carousel>
      </div>
    </div>

    <SupplementaryModal :sku="props.sku" @close="chosenBundle=null" v-if="chosenBundle" :bundle="chosenBundle"/>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/mixins.scss";

.title {
  @include smd {
    align-items: flex-start;
  }
}

.supplementary {
  background: $light-background;
  padding: 15px 0 15px 40px;

  @include smd {
    padding: 15px 0 15px 10px;
  }

  @include mobile {
    padding: 10px 10px;
    //margin-right: -16px;
  }
}

.supplementary:deep(.product) {
  max-width: 160px;
}

.green-label {
  background: $green;
  color: $white;
  font-size: 13px;
  width: fit-content;
  padding: 0 10px;
  height: 23px;
}

.slides {
  margin-left: -15px;

  @include smd {
    margin-left: 0;
  }
}
</style>
