<template>
  <div class="marks row">
    <img alt="Stars" height="14px" width="95px" src="/images/product/stars.png">
    <Text tag="span">{{ $t("product.sku") }}: {{ sku }}</Text>
  </div>
</template>


<script>
import Text from "~/components/atoms/Text.vue";

export default {
  components: {Text},
  props: {
    sku: {type: String},
  }
}
</script>

<style scoped lang="scss">
@import 'assets/scss/variables.scss';

.marks {
  color: $light-black;
  font-size: 12px;
  gap: 15px;
  align-items: center;
  margin: auto;
}

</style>
