<script setup>
import ProductPrice from "~/components/molecules/ProductPrice.vue";
import ProductUnit from "~/components/molecules/ProductUnit.vue";
import {useCurrencyStore} from "~/store/currencyStore.js";

const props = defineProps({
  activePrice: Number,
  totalArea: Number,
  status: String,
  unit: String,
  volumeIsActive: Boolean,
  min_volume_qty: Number,
  unit_per_box: Number,
  restparti: {}
})

const currencyStore = useCurrencyStore()

</script>

<template>
  <table style="width: fit-content; margin-top: auto;" v-if="status!=='Offertvara'">
    <tbody>
    <tr v-if="props.unit === 'm2'">
      <td>{{ $t('product.total_area') }}:</td>
      <td>
        <component :is="props.volumeIsActive ? 'strong' : 'span'" class="total-yta">{{ props.totalArea }}
          <ProductUnit :unit="props.unit" :sup="true"/>
        </component>
      </td>
    </tr>
    <template v-if="status==='Restparti'">
      <tr>
        <td>{{ $t('product.price_per')}} {{ $t('product.remainder')}}: &nbsp;</td>
        <td>
          <ProductPrice :light="true" class="reset" :price="activePrice"/>
        </td>
      </tr>
      <tr>
        <td>{{ $t('cart.total') }} st:</td>
        <td>{{ restparti.qty }} st</td>
      </tr>
      <tr>
        <td>{{ $t('product.price_per')}} st:</td>
        <td>{{ Math.ceil(activePrice / restparti.qty) }} {{ currencyStore.currency }}</td>
      </tr>
    </template>
    <template v-else>
      <tr>
        <td style="padding-right: 5px;">{{ $t('product.price_per') }}
          <ProductUnit :unit="props.unit" :sup="true"/>
          :
        </td>
        <td style=" position: relative;">
          <ProductPrice :light="true" class="reset" :price="activePrice"/>
          <div class="volume-is-active hidden-smd b-bronze c-dark-black text-center"
               v-if="props.volumeIsActive && props.min_volume_qty > props.unit_per_box">
            <strong>{{ $t('product.volume.volume_price') }}!</strong>
          </div>
        </td>
      </tr>
    </template>
    <tr v-if="props.unit === 'm2'">
      <td style="padding-right: 10px;">{{ $t('product.price_per') }} {{ $t('product.box') }}:</td>
      <td>
        <ProductPrice :light="true" class="reset" :price="props.activePrice * props.unit_per_box"/>
      </td>
    </tr>
    </tbody>
  </table>
  <div class="volume-is-active hidden-md-lg b-bronze c-dark-black text-center"
       v-if="props.volumeIsActive && props.min_volume_qty > props.unit_per_box">
    <strong>{{ $t('product.volume.volume_price') }}!</strong>
  </div>
</template>

<style scoped lang="scss">
table {
  border-collapse: separate;
  font-size: 12px;
  border-spacing: 0 1px;

  td {
    line-height: 16px;
  }
}
</style>
