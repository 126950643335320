<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <g clip-path="url(#clip0_8150_29768)">
      <path
          d="M7.0013 12.8327C10.223 12.8327 12.8346 10.221 12.8346 6.99935C12.8346 3.77769 10.223 1.16602 7.0013 1.16602C3.77964 1.16602 1.16797 3.77769 1.16797 6.99935C1.16797 10.221 3.77964 12.8327 7.0013 12.8327Z"
          stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M7 3.5V7L9.33333 8.16667" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_8150_29768">
        <rect width="14" height="14" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>


<script>
export default {
  name: 'ClockIcon'
}
</script>

