<script setup>
import Text from "~/components/atoms/Text.vue";
import FormInput from "~/components/atoms/FormInput.vue";
import ProductPrice from "~/components/molecules/ProductPrice.vue";
import Button from "~/components/atoms/Button.vue";
import Image from "~/components/atoms/Image.vue";
import CrossIcon from "~/components/icons/CrossIcon.vue";
import Input from "~/components/atoms/Input.vue";
import {useCartStore} from "~/store/cartStore.js";
import {useCustomFetch} from '~/composables/customFetch.js';
import CartIcon from "~/components/icons/CartIcon.vue";

const props = defineProps({
  bundle: {
    type: Object,
    required: true
  },
  sku: {
    type: String,
  }
})

const active = ref(false)
const variations = ref([])
const sizes = ref([])
const currentSize = ref(null)
const thickness = ref([])
const currentThickness = ref(null)
const currentVariation = ref(null)
const activeAlternatives = ref([])
const showFix = ref(false)
const fixCount = ref('')
const emits = defineEmits(['close'])
const store = useCartStore()

const total = computed(() => {
  return currentVariation.value.products?.reduce((acc, product) => {
    return acc + (product.qty >= product.volume_amount ? product.hc_volume_price : product.hc_standard_price) * product.qty
  }, 0)
})

const handleClose = () => {
  emits('close')
}

const toggleAlternativeList = (index) => {
  if (activeAlternatives.value.includes(index)) {
    activeAlternatives.value = activeAlternatives.value.filter((item) => item !== index)
  } else {
    activeAlternatives.value.push(index)
  }
}

const fetchBundle = () => {
  useCustomFetch(`/product/supplementary/${props.bundle.id}`, {
    method: 'GET',
    query: {
      sku: props.sku
    }
  }, true).then((response) => {
    variations.value = response.bundle.variations
    currentVariation.value = response.bundle.variations[0]
    thickness.value = response.thickness
    sizes.value = response.sizes
    currentSize.value = response.sizes[0]
    currentThickness.value = response.thickness[0]
  })
}

const addToCart = () => {
  store.addItemsToCart(currentVariation.value.products.map((product) => ({
            id: product.id,
            count: product.qty,
            supplementary: 1,
            source: props.bundle.id === 6 ? store.sources.fix_fog_calculator : store.sources.better_together_calculator
          })
      )
  )

  handleClose()
}

const changeProduct = (index, product) => {
  const alternatives = currentVariation.value.products[index].alternatives
  const qty = currentVariation.value.products[index].qty

  currentVariation.value.products[index] = {
    ...product,
    alternatives,
    qty
  }
}

const changeQty = (index, type) => {
  if (type === 'plus') {
    currentVariation.value.products[index].qty++
  } else {
    if (currentVariation.value.products[index].qty > currentVariation.value.products[index].start_qty) {
      currentVariation.value.products[index].qty--
    }
  }
}

const removeProduct = (index) => {
  currentVariation.value.products.splice(index, 1)
}

const countIndex = (product) => {
  const index = product.index

  if (!product.name.includes('Fog')) {
    return index * fixCount.value
  }

  const size = currentSize.value
  const height = currentThickness.value

  const sizes = size.split('x')
  const a = parseFloat(sizes[0])
  const b = parseFloat(sizes[1])

  let fp = (a * 10) + (b * 10);
  let sp = (a * 10) * (b * 10);

  return (((fp / sp) * height * 15) * index) * fixCount.value
}

const calculate = () => {
  currentVariation.value.products.forEach((product) => {
    product.qty = Math.ceil(countIndex(product))
  })

  showFix.value = true
}

onMounted(() => {
  fetchBundle()
})
</script>

<template>
  <div class="g-32 main-container">
    <div class="g-24 no-flex">
      <div class="bundle-container no-flex">
        <div class="variations row full-width flex-center g-16 wrap">
          <div class="variation no-flex clickable" :class="{active:currentVariation.name === variation.name}"
               v-for="variation in variations" @click="currentVariation = variation">
            <Text tag="strong">{{ variation.name }}</Text>
          </div>
        </div>
      </div>

      <template v-if="currentVariation">
        <div class="bundle-container description no-flex g-8 the-same-gap">
          <template v-if="currentVariation?.custom">

            <template v-if="bundle.id === 4">
              <div class="row g-8 the-same-gap">
                <FormInput v-model="currentSize" type="select" label="Sizes"
                           :options="sizes.map(size => ({key:size, value:size}))"/>

                <FormInput v-model="currentThickness" type="select" label="Thickness"
                           :options="thickness.map((item) => ({key:item, value:item+'mm'}))"/>
              </div>
            </template>

            <div class="supplementary-input row full-width g-8 the-same-gap">
              <FormInput v-model="fixCount" :label="$t('product.enter_m2')" min="1"/>
              <Button type="black tall" @click="fixCount ? calculate() : false">{{ $t("product.figure_out") }}</Button>
            </div>
          </template>
          <Text>{{ currentVariation.description }}</Text>
        </div>
      </template>
    </div>

    <template v-if="(currentVariation && !currentVariation?.custom) || (currentVariation?.custom && showFix)">
      <div class="products full-width no-flex">
        <template v-for="(product,index) in currentVariation.products">
          <div class="variation-product bundle-container full-width row space-between flex-start stretch">
            <div class="row g-8 flex-start no-flex">
              <div class="image" :key="product.image">
                <Image :src="product.image"/>
              </div>
              <div class="g-8">
                <nuxt-link :to="'/shop/'+product.url" class="price">{{ product.name }}</nuxt-link>
                <Text>
                  <ProductPrice class="price"
                                :price="product.qty >= product.volume_amount ? product.hc_volume_price : product.hc_standard_price"
                                :digits="0"/>
                </Text>

                <div class="row g-4">
                  <div v-if="product.color" class="color"
                       :style="{background: product.color}"></div>
                  <Button class="change" @click="toggleAlternativeList(index)" type="black"
                          v-if="product.alternatives?.length">{{
                      $t("base.change")
                    }}
                  </Button>
                </div>
              </div>
            </div>
            <div class="no-flex flex-end space-between">
              <CrossIcon class="clickable" @click="removeProduct(index)"/>

              <div class="row">
                <Button class="calculating-btn" type="minus" @click="changeQty(index,'minus')"/>
                <Input v-model="product.qty" style="background: transparent;" disabled width="26px"/>
                <Button class="calculating-btn" type="plus" @click="changeQty(index,'plus')"/>
              </div>
            </div>
          </div>
          <div class="alternatives" v-if="product.alternatives.length && activeAlternatives.includes(index)">
            <div class="bundle-container no-flex">
              <div class="variations row full-width flex-center g-16 wrap">
                <div class="variation no-flex clickable" @click="changeProduct(index, alternative_product)"
                     v-for="alternative_product in product.alternatives"
                     :class="{active:product.id === alternative_product.id}">
                  <Text tag="strong">{{ alternative_product.variation_name }}</Text>
                </div>
              </div>
            </div>
          </div>
        </template>

        <div class="bundle-container row space-between full-width" style="margin-top: 16px;">
          {{ $t("base.total") }}
          <Text tag="strong" class="total">
            <ProductPrice :price="total"/>
          </Text>
        </div>
      </div>

      <div class="bundle-container full-width g-8 bottom-container">
        <div class="no-flex g-16">
          <slot/>
          <Button type="black" class="black black full-width tall coarse uppercase" @click="addToCart">
            <CartIcon width="18px" height="18px" stroke="white"/> &nbsp;
            {{ $t('cart.add_to_cart') }}
          </Button>
        </div>
      </div>
    </template>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/mixins.scss";

.bundle-container {
  padding: 0 32px;

  @include smd {
    padding: 0 24px;
  }
}

.add-to-cart {
  @include mobile {
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 100%;
    border: none;
    background-color: #000;
    color: #fff;
    font-weight: 600;
    padding: 0 36px;
    cursor: pointer;
    border-radius: 25px;
    font-size: 14px !important;
    text-transform: uppercase;
  }
}

.variation {
  padding: 7px 18px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
  border-radius: 8px;
  font-size: 16px;
  color: $black;

  @include smd {
    font-size: 13px;
  }

  &:hover {
    background: hsla(0, 0%, 97.6%, .9);
  }

  &.active {
    background: $dark-black;
    color: $white;
  }
}

.description {
  font-size: 13px;
}

.variation-product {
  background: $light-background;
  padding-top: 12px;
  padding-bottom: 12px;
}

.image {
  min-width: 68px;
  min-height: 68px;
  max-width: 68px;
  max-height: 68px;
}

.calculating-btn {
  width: 25px !important;
  height: 25px !important;
}

.price {
  font-size: 12px;
}

.change {
  width: 58px !important;
  height: 21px !important;
  font-size: 11px;
  border-radius: 10px;
}

.alternatives {
  margin: 14px 0;
}

.main-container {
  margin-bottom: 32px;
}

.total .price {
  font-size: 14px;
}

.add-to-cart {
  font-size: 16px;
  font-weight: 600;
}

.bottom-container {
  margin-top: auto;
  justify-content: flex-end;
}

.color {
  width: 21px;
  height: 21px;
  border-radius: 100%;
  margin-right: 10px;
  border: 1px solid;
}

.input {
  flex: 1;
  border-radius: 5px;
  height: 35px;
  border: 1px solid $border-grey;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 600;
}
</style>
